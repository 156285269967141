<template>
  <v-container
    id="settings"
    fluid
    tag="section"
  >
    <v-row>
      <v-col>
        <div class="text-h4 font-weight-light">
          <p />
          <p>
            CareConnect system settings
          </p>
        </div>
        <div />
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row
              justify="center"
              align="center"
            >
              <span class="text-h4 font-weight-light">
                Maintenance breaks
              </span>
              <v-spacer />
              <v-dialog
                v-model="dialog"
                persistent
                width="800"
                scrollable
                eager
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    color="primary"
                    outlined
                    :disabled="isAnyUpdateable"
                    @click="schedule"
                    v-on="on"
                  >
                    Schedule new maintenance break
                  </v-btn>
                </template>
                <v-card>
                  <base-app-dialog-title
                    title="Schedule maintenance"
                    @close="closeDialog"
                  />
                  <v-card-text>
                    <v-row>
                      <v-col cols="6">
                        <base-app-date-time-picker
                          v-if="dialog"
                          v-model="start"
                          title="Start"
                        />
                      </v-col>
                      <v-col cols="6">
                        <base-app-date-time-picker
                          v-if="dialog"
                          v-model="end"
                          title="End"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer />
                    <base-app-dialog-action-button
                      :disabled="(!start || !end)"
                      @click="confirmSchedule"
                    >
                      Schedule
                    </base-app-dialog-action-button>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="maintenances"
              disable-pagination
              hide-default-footer
              item-key="start"
              :loading="loading"
            >
              <template v-slot:item.actions="{ item }">
                <v-col>
                  <v-btn
                    v-if="updateable(item)"
                    class="ma-1"
                    color="primary"
                    outlined
                    @click.stop="updateMaintenance(item)"
                  >
                    Update
                  </v-btn>
                  <v-btn
                    v-if="inFuture(item.start)"
                    class="ma-1"
                    color="ccred"
                    outlined
                    @click.stop="removeMaintenance(item)"
                  >
                    Remove
                  </v-btn>
                </v-col>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { eventBus, busEvents } from '@/EventBus'
  import { mapMutations } from 'vuex'
  import isAfter from 'date-fns/isAfter/index'

  export default {
    name: 'SettingsView',
    components: {
    },
    data () {
      return {
        loading: false,
        start: null,
        end: null,
        maintenances: [],
        headers: [
          {
            sortable: true,
            text: 'Start',
            value: 'start',
          },
          {
            sortable: true,
            text: 'End',
            value: 'end',
          },
          {
            sortable: true,
            text: 'Duration (min)',
            value: 'durationInMinutes',
          },
          {
            sortable: false,
            value: 'actions',
            align: 'end',
          },
        ],
        dialog: false,
      }
    },
    computed: {
      isAnyUpdateable () {
        const now = new Date().toISOString()
        const res = this.maintenances.some(e => e.end > now)
        return res
      },
    },
    mounted () {
      this.loadData()
    },
    methods: {
      ...mapMutations({
        setLoading: 'app/SET_LOADING',
      }),
      schedule () {
        this.dialog = true
      },
      confirmSchedule () {
        const { start, end } = this
        this.$careConnect.upsertMaintenance({ start, end })
          .then(resp => {
            this.loadData()
          })
          .catch(err => {
            const text = err.json ? err.json.error : 'Failed to schedule maintenance'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
            this.setLoading(false)
            this.dialog = false
          })
      },
      loadData () {
        this.loading = true
        this.$careConnect.getMaintenances()
          .then(resp => {
            const now = new Date().toISOString()
            this.maintenances = resp.map((e) => {
              if (e.start < now && e.end > now) {
                e.isScheduled = 'Current'
              }
              return e
            })
          })
          .catch(err => {
            const text = err.json ? err.json.error : 'Failed to load maintenance info'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
            this.loading = false
            this.setLoading(false)
          })
      },
      inFuture (start) {
        return isAfter(new Date(start), new Date())
      },
      updateable (e) {
        const now = new Date().toISOString()
        return (e.end > now)
      },
      updateMaintenance (e) {
        this.end = e.end
        this.start = e.start
        this.dialog = true
      },
      removeMaintenance (item) {
        this.$careConnect.removeMaintenance(item.start)
          .then(resp => {
            this.loadData()
          })
          .catch(err => {
            const text = err.json ? err.json.error : 'Failed to remove maintenance'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
            this.setLoading(false)
          })
      },
      closeDialog () {
        this.start = null
        this.end = null
        this.dialog = false
      },
    },
  }
</script>
